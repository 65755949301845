import React from 'react';

const Weiterempfehlen = () => {
    return (
        <div>
            <div className="columns bg is-multiline">
                <div className="column bg is-two-fifths ">
                    <div className="container-l bg bottom has-text-centered">
                    </div>
                </div>
                <div className="column bg is-three-fifths">
                    <div className="container-r bg bottom has-text-centered"><br/>
                    <h2 className="plantext offernumtext has-text-centered">So einfach geht‘s:<br/>  
                                Weiterempfehlen und Prämie sichern!</h2><br/>
                        <div className="columns is-multiline arrcol">
                            <div className="column">
                                <img className="mid" src="/img/IconStep1.jpg"
                                        alt="image"
                                        width="100px"
                                        height="100px"/>
                                        <h2 className="title offernumtext descr middletext">
                                        Sie sind mit der installierten Anlage zufrieden und empfehlen uns weiter.
                                        </h2>
                            </div>
                            <div className="column arrwcol">
                                <img className="arrw"
                                src="/img/sidearrow.jpg"
                                        alt="image"
                                       />
                            </div>
                            <div className="column">
                            <img className="mid" src="/img/IconStep2.jpg"
                                        alt="image"
                                        width="100px"
                                        height="100px"/>
                                        <h2 className="title offernumtext descr middletext">
                                        Ihr geworbener Kunde nimmt über 
                                        www.meinalarm24.de/kunden- 
                                        werben-kunden/ Kontakt zu uns auf 
                                        und erwähnt vor der 
                                        Auftragserteilung Ihren Namen. Der 
                                        Rabatt wird bei Auftragserteilung direkt berücksichtigt.
                                        </h2>
                            </div>
                            <div className="column arrwcol">
                                <img className="arrw"
                                src="/img/sidearrow.jpg"
                                        alt="image"
                                       />
                            </div>
                            <div className="column">
                            <img className="mid" src="/img/IconStep3.jpg"
                                        alt="image"
                                        width="100px"
                                        height="100px"/>
                                        <h2 className="title offernumtext descr middletext">
                                        Sie erhalten nach Bezahlung der 
                                        Anlage als Dankeschön Ihre 
                                        Prämie in Form von einer 
                                        Gutschrift oder einem Sachwert.
                                        </h2>
                            </div>
                        </div>
                       {/* <a className="button .is-centered reisrechner-btn preisrechner-btn-primary mailto" href="mailto:contact@gmail.com">
                       Jetzt per E-Mail weiterempfehlen!
                    </a> */}
                    </div>
                </div>
            </div>
        </div>   
    );
};

export default Weiterempfehlen;
