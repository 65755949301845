import React from 'react';
import Inhalte from '../components/Inhalte';
import Weiterempfehlen from '../components/Weiterempfehlen';
import {useRef} from 'react';

const Geworben = () => {
    const weiterempfehlen = useRef();
    const Pramie = useRef();

    const handleClick = () => {
      weiterempfehlen.current.scrollIntoView({behavior: 'smooth',block: "nearest"});
    };

    const handleClick1 = () => {
      Pramie.current.scrollIntoView({behavior: 'smooth',block: "start" });
    };

    return (
        <div >
        <div className="section geworben" >
        <h2 className="title has-text-centered">Das MeinAlarm24 Kunden-werben-Kunden Programm</h2>
                <div className="columns is-multiline">
                <div className="column is-half ">
                    <div className="container-l has-text-centered">
                    <div>
                    <h1 className="has-text-centered">Sie wurden geworben?</h1></div>
                    <div className="buttonbtm">
                    <button className="button is-centered preisrechner-btn preisrechner-btn-primary" onClick={handleClick1}>
                     Jetzt Prämie sichern! </button></div></div>
                </div>
                <div className="column is-half">
                    <div className="container-r has-text-centered">
                    <div>
                    <h1 className="has-text-centered">Sie wollen jemanden werben?</h1></div>
                    <div className="buttonbtm">
                    <button className="button is-centered reisrechner-btn preisrechner-btn-primary" onClick={handleClick}>
                     Jetzt weiterempfehlen!
                    </button></div></div>
                </div>
                </div>
        </div>
        <div className="section inhalte">
            <h2 className="title has-text-centered">Die Inhalte unseres Kunden-werben-Kunden Programms</h2>
            <Inhalte/>
        </div> 
        <div className="section weiterempfehlen" ref={weiterempfehlen}>
            <h2 className="title bg has-text-centered">Sie wollen jemanden werben?</h2>
            <Weiterempfehlen/>
        </div>
        <div className="section pramie" ref={Pramie}>
            <h2 className="title has-text-centered">Sie wurden geworben? Jetzt Prämie sichern!</h2>
        </div>
    </div>
    );
};

export default Geworben;
