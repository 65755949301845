import React from 'react';

const Inhalte = () => {
    return (
        <div>
           <div className="columns is-multiline">
                <div className="column is-three-fifths">
                    <div className="container-l plantext has-text-centered">
                    <p className="has-text-centered">
                        Empfehlen Sie Freunden, Verwandten, Bekannten und
                         Kollegen MeinAlarm24 als professionellen Partner für
                          Sicherheitstechnik. Wir bedanken uns!
                        </p><br/><h3 className="has-text-centered middletext">Die Höhe der Prämie richtet sich nach dem Brutto-Anlagenwert und wird <br/>
                        zwischen dem Werbenden und dem Geworbenen aufgeteilt.</h3><br/><h3 className="has-text-centered middletext">
                        Bei einem Anschaffungspreis von:
                        <ul><p className="offernumtext">
                        <li className="a">1.500 – 2.500€ = 125€ für jeden –<span className="numtextblue"> 250€ Prämie</span></li>
                        <li className="a">2.500 – 5.000€ = 250€ für jeden – <span className="numtextblue">500€ Prämie</span></li>
                        <li className="a">5.000 – 7.500€ = 375€ für jeden – <span className="numtextblue">750€ Prämie</span></li>
                        <li className="a">> 7.500€&ensp;&ensp;&ensp;&ensp;= 500€ für jeden – <span className="numtextred">1.000€ Prämie</span></li>
                        </p></ul> </h3> 
                    </div>
                </div>
                <div className="column is-two-fifths">
                    <div className="container-r bottom has-text-centered">
                    <h1 className="has-text-centered has-text-centered">Bis zu 1.000€ Prämie!</h1>
                    </div>
                </div>
            </div>
        </div>   
    );
};

export default Inhalte;
